import { useLocation, useNavigate } from "react-router-dom";
import { useLazyValidarSesionQuery } from "../../services/docs";
import { toastr } from "react-redux-toastr";
import { useDispatch } from "react-redux";
import { iniciarSesion } from "../../features/ui/uiSlice";
import { useEffect } from "react";
import { Spinner } from "../../components/Spinner";

export const AuthTokenPage = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const [action, { data, isLoading }] = useLazyValidarSesionQuery();
  const searchParams = new URLSearchParams(location.search);
  const dispatch = useDispatch();

  useEffect(() => {
    if (searchParams) {
      console.log(searchParams.get("t"));
      if (!searchParams.get("t") || !searchParams.get("url")) {
        navigation("/auth/logín", { replace: true });
      }
      iniciarSesionAction(dispatch);
    }
  }, []);

  const iniciarSesionAction = async (dispatchz: any) => {
    localStorage.setItem("app_token", searchParams.get("t"));
    const resp = await action()
      .unwrap()
      .catch((err: any) => {
        // window.location.href = searchParams.get("url");
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (resp) {
      dispatchz(iniciarSesion(resp.user));
      navigation("/home/inicio");
      console.log(resp);
    }
  };
  if (isLoading) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
  if (!isLoading && data) {
    return (
      <div className="flex w-full justify-center">
        <Spinner />
      </div>
    );
  }
};
