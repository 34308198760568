import { useForm } from "../../hooks/useForm";
import {
  useLazyValidarSesionQuery,
  useLoginMutation,
  useValidarSesionQuery,
} from "../../services/docs";
import { useNavigate } from "react-router-dom";
import { Logo } from "../../components/ui/Logo";
import {
  GoogleReCaptcha,
  GoogleReCaptchaProvider,
  useGoogleReCaptcha,
} from "react-google-recaptcha-v3";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/Alert";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { agregarToken, iniciarSesion } from "../../features/ui/uiSlice";
import { Label } from "../../components/ui/Label";
import { Input } from "../../components/ui/Input";
import Button from "../../components/ui/Button";
import { toastr } from "react-redux-toastr";
import { Spinner } from "../../components/Spinner";

import logo from "/images/amex_insuranceservices_logo_v.svg";

export const LoginScreen = () => {
  const captchaRef = useRef(null);
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [conPin, setConPin] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loginSend, { isLoading, error, isSuccess, reset }] =
    useLoginMutation();
  const { formulario, handleInputChange } = useForm({
    password: "",
    email: "",
    pin: null,
  });
  const { password, email, pin } = formulario;
  const handleLogin = async (e: any) => {
    e.preventDefault();
    const cap = await executeRecaptcha();
    if (!cap) {
      toastr.error("Recapcha No valido Favor de intentar de nuevo");
      return;
    }
    let post = { ...formulario, gRecaptchaToken: cap };
    if (!conPin) {
      delete post.pin;
    }
    const resp = await loginSend(post)
      .unwrap()
      .catch((err: any) => {
        if (err.status === 400) {
          if (err.data.message) {
            if (Array.isArray(err.data.message)) {
              err.data.message.map((m: any) => {
                toastr.error(m);
              });
            } else {
              toastr.error(err.data.message);
            }
          }
        }
      });
    if (resp) {
      if (resp.message) {
        setConPin(!conPin);
        toastr.error(resp.message);
        return;
      }
      dispatch(agregarToken(resp.original.access_token));
      dispatch(iniciarSesion(resp.original.usuario));
      if (resp.original.usuario.profile === "emisiones") {
        navigate("/emisiones/inicio", { replace: true });
        return;
      }
      navigate("/home/inicio", { replace: true });
    }
  };
  return (
    <>
      <div className="relative grid max-h-full min-h-full min-w-full max-w-full lg:grid-cols-1">
        <div
          className="fixed inset-0 !h-[100dvh] w-[53vw] bg-gradient-to-b from-primary to-primary-light"
          style={{
            clipPath: "polygon(0 0, 100% 0%, 88% 100%, 0% 100%)",
          }}
        >
          <img
            alt="insuranceservices pattern"
            src="/images/bg-pattern-insuranceservices.png"
            width={1920}
            height={1080}
            sizes="(max-width: 639px) 100vw, (max-width: 1079px) 50vw, 1079px"
            className="inset-0 z-[1] h-full w-full object-cover opacity-10"
          />
        </div>

        <div className="absolute left-1/2 top-1/2 w-full max-w-md -translate-x-1/2 translate-y-1/2">
          <div className="card flex max-h-full flex-col items-center justify-center space-y-4 overflow-y-auto">
            <img src={logo} width={200} />
            <form className="w-full space-y-6" action="#" method="POST">
              <div>
                <Label>Correo Electronico</Label>
                <Input
                  name="email"
                  value={email}
                  onChange={handleInputChange}
                  type="text"
                />
              </div>

              {!conPin && (
                <div>
                  <Label>Contraseña</Label>
                  <Input
                    name="password"
                    value={password}
                    onChange={handleInputChange}
                    type="password"
                  />
                </div>
              )}
              {conPin && (
                <div>
                  <Label>Pin</Label>
                  <Input
                    name="pin"
                    value={pin}
                    onChange={handleInputChange}
                    type="password"
                  />
                </div>
              )}
              <div>
                <Alert className="p-2 text-red-600">
                  <AlertTitle className="text-[11px] font-bold">
                    Importante:
                  </AlertTitle>
                  <AlertDescription className="text-[9px]">
                    Este sistema está restringido a personas autorizadas para
                    fines comerciales. Las personas no deben tener una
                    expectativa de privacidad al usar este sistema. Las personas
                    responsables del acceso no autorizado o uso inapropiado que
                    resulte en daños a socios y/o clientes pueden estar sujetos
                    a acciones civiles o penales.
                  </AlertDescription>
                </Alert>
              </div>
              <div>
                {isLoading && (
                  <div className="flex w-full justify-center">
                    <Spinner />
                  </div>
                )}
                {!isLoading && (
                  <Button size="lg" className="w-full" onClick={handleLogin}>
                    Iniciar Sesión
                  </Button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
